import { Check } from "@/app/svg/icons/check/Check";
import clsx from "clsx";
import { useLocale } from "next-intl";
import { useFormStatus } from "react-dom";

export const SubmitBtn = ({
  children,
  isFormValid,
  isFullWidth,
  isSuccess,
  btnColor = "btn-secondary",
  disabled = false,
}: {
  children: React.ReactNode;
  isFormValid: boolean;
  isFullWidth?: boolean;
  isSuccess: boolean;
  btnColor?: string;
  disabled?: boolean;
}) => {
  const { pending } = useFormStatus();
  const locale = useLocale();
  const t = text[locale];
  return (
    <button
      className={clsx("btn transition-all duration-500", {
        [btnColor]: !isSuccess,
        "btn-success text-neutral": isSuccess,
        "btn-disabled": !isFormValid,
        "pointer-events-none": pending || isSuccess || !isFormValid,
        "w-full": isFullWidth,
      })}
      disabled={disabled || pending}
    >
      {pending ? (
        <>
          {t.loading}...
          {""}
          <span className="loading loading-spinner loading-xs" />
        </>
      ) : isSuccess ? (
        <>
          {t.success}
          <Check />
        </>
      ) : (
        <>{children}</>
      )}
    </button>
  );
};

const text = {
  en: {
    success: "Success!",
    loading: "Loading",
  },
  de: {
    success: "Erfolgreich!",
    loading: "Lädt",
  },
};
