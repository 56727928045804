"use client";
import { useEffect, useState } from "react";
import { useFormState } from "react-dom";
import { OnboardingModal } from "./OnboardingModal";
import { submitOnboarding } from "@/app/actions/submitOnboarding";

function clientAction(_: any, formData: FormData) {
  return submitOnboarding(formData);
}

export function MaybeOnboardingModal({ isOnboarded, t }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formState, formAction] = useFormState(clientAction, {
    error: null,
    success: false,
  });

  useEffect(() => {
    if (!isOnboarded) {
      setTimeout(() => {
        setIsModalOpen(true);
      }, 300);
      return;
    }
  }, [isOnboarded]);

  if (!isModalOpen) {
    return null;
  }

  return (
    <OnboardingModal
      formAction={formAction}
      formState={formState}
      t={t}
      setIsModalOpen={setIsModalOpen}
    />
  );
}
