import { DateTime } from "luxon";
import { z } from "zod";
import { validDistricts, validMediums } from "@/app/constants";
import { maxDate, minDate } from "../lib/minMaxDOBChoice";
import { isValidPhoneNumber } from "libphonenumber-js/min";

export const onboardingSchema = z.object({
  firstName: z.string().min(2),
  lastName: z.string().min(2),
  stage: z
    .string()
    .refine((value) => value === "kita_search" || value === "")
    .optional(),
  dob: z.string().refine((dateString) => {
    const parsedDate = DateTime.fromISO(dateString);
    return parsedDate.isValid && parsedDate >= minDate && parsedDate <= maxDate;
  }),
  district: z
    .string()
    .refine((value) => validDistricts.find((v) => v.key === value)),
  medium: z.string().refine((value) => validMediums.includes(value)),
  otherMedium: z
    .string()
    .refine((value) => value.length > 0)
    .optional(),
  referral: z
    .string()
    .refine((value) => value.length > 0)
    .optional(),
  language: z.string().refine((value) => ["en", "de"].includes(value)),
  phone: z
    .string()
    .refine((value) => !value || isValidPhoneNumber(value))
    .optional(),
});
